<template>
  <div class="col-12 above offwhite_bg remaining_height">
    <div class="row mx-0 justify-content-center">
      <div class="col-12 my-4 text-center medium bigger_font bold">
        <span>Setup your Recurring Donation</span>
      </div>
    </div>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'DesktopPage'
}
</script>

<style scoped>
.above {
  z-index: inherit;
}
.offwhite_bg {
  background-color: rgb(249, 250, 251);
}
.remaining_height {
  height: calc(100vh - 213px - 71px - env(safe-area-inset-bottom));
  overflow-y: auto;
}
.bigger_font {
  font-size: 20px;
  color: var(--green-color-dark);
}

@media (min-width: 992px) {
  .remaining_height {
    height: calc(100vh - 133px - 111px - 71px);
  }
  .bigger_font {
    font-size: 23px;
  }
}

</style>
